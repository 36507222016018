// Async variable names.
export const ACCESS_TOKEN = 'ADANI_HTL_ACCESS_TOKEN';
export const REFRESH_TOKEN = 'ADANI_HTL_REFRESH_TOKEN';
export const AES_KEY = 'ADANI_HTL_AES_KEY';
export const USER_ROLE = 'ADANI_HTL_USER_ROLE';

// error
export const TOKEN_EXPIRE_ERROR = 'Token Already Expired';

// Date format
export const DateFormat1 = 'Do MMMM YYYY';
export const DateFormat2 = 'D MMM YYYY';
export const DateFormat3 = 'YYYY-MM-DD';
export const DateFormat4 = 'D MMM YYYY, HH:mm:ss';
export const DateFormat5 = 'MM/DD/YYYY HH:mm:ss';
export const DateFormat6 = 'DD/MM/YYYY';
export const TimeFormat = 'HH:mm:ss';

// Google map
export const GOOGLE_MAP_API_KEY = process.env.REACT_APP_GMAP_KEY;
export const defaultCenter = {
  lat: '20.5937',
  lng: '78.9629'
};
export const mapStyleWithoutLabel = [{ elementType: 'labels', stylers: [{ visibility: 'off' }] }];

export const userRoleText: any = {
  workspace_manager: 'Workspace Manager',
  regular: 'User',
  manager: 'Manager'
};

export const aoiRouteColors = [
  '#983628',
  '#00BFBF',
  '#95753D',
  '#DD74DD',
  '#74FFFF',
  '#D171A8',
  '#AA94D0',
  '#993325',
  '#5AD5D5',
  '#7FFF7F',
  '#FF7F7F',
  '#4DDDDD',
  '#255ADF',
  '#0F7F7F',
  '#00C3B8',
  '#6532CD',
  '#B5974B',
  '#8F6F6F',
  '#2D4F73',
  '#A6005F',
  '#435070',
  '#00A483',
  '#780120',
  '#21C3C3',
  '#D0B0B0',
  '#B5A275',
  '#9441DC',
  '#B6A61E',
  '#74B5EC',
  '#2D96E1',
  '#DF4D55',
  '#3D997A',
  '#FF851B',
  '#85144B',
  '#B10DC9',
  '#2ECC40',
  '#001f3f',
  '#39CCCC',
  '#7FDBFF',
  '#FFDC00',
  '#F012BE'
];

export const aoiBackColors: { [key: string]: string } = {
  '#983628': 'rgba(152, 54, 40, 0.2)',
  '#00BFBF': 'rgba(0, 191, 191, 0.2)',
  '#95753D': 'rgba(149, 117, 61, 0.2)',
  '#DD74DD': 'rgba(221, 116, 221, 0.2)',
  '#74FFFF': 'rgba(116, 255, 255, 0.2)',
  '#D171A8': 'rgba(209, 113, 168, 0.2)',
  '#AA94D0': 'rgba(170, 148, 208, 0.2)',
  '#993325': 'rgba(153, 51, 37, 0.2)',
  '#5AD5D5': 'rgba(90, 213, 213, 0.2)',
  '#7FFF7F': 'rgba(127, 255, 127, 0.2)',
  '#FF7F7F': 'rgba(255, 127, 127, 0.2)',
  '#4DDDDD': 'rgba(77, 221, 221, 0.2)',
  '#255ADF': 'rgba(37, 90, 223, 0.2)',
  '#0F7F7F': 'rgba(15, 127, 127, 0.2)',
  '#00C3B8': 'rgba(0, 195, 184, 0.2)',
  '#6532CD': 'rgba(101, 50, 205, 0.2)',
  '#B5974B': 'rgba(181, 151, 75, 0.2)',
  '#8F6F6F': 'rgba(143, 111, 111, 0.2)',
  '#2D4F73': 'rgba(45, 79, 115, 0.2)',
  '#A6005F': 'rgba(166, 0, 95, 0.2)',
  '#435070': 'rgba(67, 80, 112, 0.2)',
  '#00A483': 'rgba(0, 164, 131, 0.2)',
  '#780120': 'rgba(120, 1, 32, 0.2)',
  '#21C3C3': 'rgba(33, 195, 195, 0.2)',
  '#D0B0B0': 'rgba(208, 176, 176, 0.2)',
  '#B5A275': 'rgba(181, 162, 117, 0.2)',
  '#9441DC': 'rgba(148, 65, 220, 0.2)',
  '#B6A61E': 'rgba(182, 166, 30, 0.2)',
  '#74B5EC': 'rgba(116, 181, 236, 0.2)',
  '#2D96E1': 'rgba(45, 150, 225, 0.2)',
  '#DF4D55': 'rgba(223, 77, 85, 0.2)',
  '#3D997A': 'rgba(61, 153, 122, 0.2)',
  '#FF851B': 'rgba(255, 133, 27, 0.2)',
  '#85144B': 'rgba(133, 20, 75, 0.2)',
  '#B10DC9': 'rgba(177, 13, 201, 0.2)',
  '#2ECC40': 'rgba(46, 204, 64, 0.2)',
  '#001f3f': 'rgba(0, 31, 63, 0.2)',
  '#39CCCC': 'rgba(57, 204, 204, 0.2)',
  '#7FDBFF': 'rgba(127, 219, 255, 0.2)',
  '#FFDC00': 'rgba(255, 220, 0, 0.2)',
  '#F012BE': 'rgba(240, 18, 190, 0.2)',
  '#CCCCCC': 'rgba(204, 204, 204, 0.2)',
  '#F24E1E': 'rgba(242, 78, 30, 0.2)',
  '#D60000': 'rgba(214, 0, 0, 0.2)'
};

export const rasterlayerBandColor = [
  'case',
  ['==', ['band', 1], -9999],
  [0, 0, 0, 0],
  ['==', ['band', 1], 1],
  [253, 243, 23, 0.6],
  ['==', ['band', 1], 2],
  [254, 255, 115, 0.6],
  ['==', ['band', 1], 3],
  [254, 0, 0, 0.6],
  ['==', ['band', 1], 4],
  [205, 0, 185, 0.6],
  ['==', ['band', 1], 5],
  [95, 0, 91, 0.6],
  ['==', ['band', 1], 6],
  [139, 0, 0, 0.6],
  ['==', ['band', 1], 7],
  [38, 115, 0, 0.6],
  ['==', ['band', 1], 8],
  [150, 231, 138, 0.6],
  ['==', ['band', 1], 9],
  [203, 65, 65, 0.6],
  ['==', ['band', 1], 10],
  [115, 69, 1, 0.6],
  ['==', ['band', 1], 11],
  [128, 0, 0, 0.6],
  ['==', ['band', 1], 12],
  [0, 112, 215, 0.6],
  ['==', ['band', 1], 13],
  [140, 163, 2, 0.6],
  ['==', ['band', 1], 14],
  [116, 0, 207, 0.6],
  ['==', ['band', 1], 15],
  [236, 153, 77, 0.6],
  ['==', ['band', 1], 16],
  [222, 88, 46, 0.6],
  ['==', ['band', 1], 17],
  [255, 236, 69, 0.6],
  ['==', ['band', 1], 18],
  [181, 213, 40, 0.6],
  ['==', ['band', 1], 19],
  [24, 94, 79, 0.6],
  ['==', ['band', 1], 20],
  [255, 150, 232, 0.6],
  ['==', ['band', 1], 21],
  [229, 207, 254, 0.6],
  ['==', ['band', 1], 22],
  [210, 9, 255, 0.6],
  ['==', ['band', 1], 23],
  [224, 171, 241, 0.6],
  ['==', ['band', 1], 24],
  [201, 133, 68, 0.6],
  ['==', ['band', 1], 25],
  [211, 211, 211, 0.6],
  ['==', ['band', 1], 26],
  [144, 144, 144, 0.6],
  ['==', ['band', 1], 27],
  [174, 72, 255, 0.6],
  ['==', ['band', 1], 28],
  [184, 143, 0, 0.6],
  ['==', ['band', 1], 29],
  [128, 2, 227, 0.6],
  ['==', ['band', 1], 30],
  [0, 102, 255, 0.6],
  ['==', ['band', 1], 31],
  [70, 130, 180, 0.6],
  ['==', ['band', 1], 32],
  [107, 142, 35, 0.6],
  ['==', ['band', 1], 33],
  [93, 216, 255, 0.6],
  ['==', ['band', 1], 34],
  [30, 144, 255, 0.6],
  ['==', ['band', 1], 35],
  [0, 61, 222, 0.6],
  ['==', ['band', 1], 36],
  [184, 214, 0, 0.6],
  ['==', ['band', 1], 37],
  [0, 168, 132, 0.6],
  ['==', ['band', 1], 38],
  [53, 181, 222, 0.6],
  ['==', ['band', 1], 39],
  [99, 152, 254, 0.6],
  ['==', ['band', 1], 40],
  [164, 160, 242, 0.6],
  ['==', ['band', 1], 41],
  [128, 2, 227, 0.6],
  ['==', ['band', 1], 42],
  [233, 214, 44, 0.6],
  ['==', ['band', 1], 43],
  [122, 200, 0, 0.6],
  ['==', ['band', 1], 44],
  [71, 222, 186, 0.6],
  ['==', ['band', 1], 45],
  [161, 0, 0, 0.6],
  ['==', ['band', 1], 46],
  [103, 201, 215, 0.6],
  ['==', ['band', 1], 47],
  [29, 167, 198, 0.6],
  ['==', ['band', 1], 48],
  [31, 118, 181, 0.6],
  ['==', ['band', 1], 49],
  [4, 62, 149, 0.6],
  ['==', ['band', 1], 50],
  [210, 180, 140, 0.6],
  ['==', ['band', 1], 51],
  [8, 98, 232, 0.6],
  ['==', ['band', 1], 52],
  [206, 0, 0, 0.6],
  ['==', ['band', 1], 53],
  [182, 0, 44, 0.6],
  ['==', ['band', 1], 54],
  [9, 245, 150, 0.6],
  ['==', ['band', 1], 55],
  [4, 250, 150, 0.6],
  ['==', ['band', 1], 56],
  [77, 77, 77, 0.6],
  [0, 0, 0, 0]
];
