import { Card, Col, Dropdown, Menu, Row, Typography } from 'antd';
import { isEmpty } from 'lodash';
import { getLength } from 'ol/sphere';
import { LineString } from 'ol/geom';
import { fromLonLat } from 'ol/proj';
import { lightred1, greyShed5, greyShed6, white } from 'constants/theme.constants';
import { AOIDetail, AOIStausType } from 'store/aoi/types';
import { RouteDetail } from 'store/route/types';
import InvalidIcon from 'assets/svg/InvalidIcon';
import RasterEnabledIcon from 'assets/svg/RasterEnabledIcon';
import RasterDisabledIcon from 'assets/svg/RasterDisabledIcon';
import VerticalThreeDotIcon from 'assets/svg/VerticalThreeDotIcon';
import { ACCESS_TOKEN } from 'constants/common.constant';
import { useAppDispatch, useAppSelector } from 'hooks/useReduxHook';
import { downloadRouteDetails } from 'store/route/actions';
import { UserPermission } from 'store/users/types';
import { ProjectState } from 'store/projects/types';
import { getMember } from 'store/projects';
import { geoJsonTOKmlDownloadFile, geoJsonTOKmlTowerDownloadFile, getAOIArea } from 'utils/util';
import { getAsyncStorageValue } from 'utils/localStorage';

const { Text } = Typography;

interface AOIRouteInvalidCardProps {
  aoiRouteDetail: AOIDetail | RouteDetail;
  AOIRouteRasterData: AOIDetail | RouteDetail | null;
  title: string;
  selectedAoiRouteId: string | null;
  handleEditRoute: (route: RouteDetail) => void;
  handleEditAOI: (aoiId: AOIDetail | null) => void;
  handleChangeAOI: (aoiId: string) => void;
  handleChangeRoute: (route: RouteDetail) => void;
  handleShowHideRaster: (route: AOIDetail | RouteDetail | null) => void;
  isEdit?: boolean;
}

const AOIRouteInvalidCard = ({
  title,
  isEdit,
  aoiRouteDetail,
  selectedAoiRouteId,
  AOIRouteRasterData,
  handleEditAOI,
  handleEditRoute,
  handleChangeAOI,
  handleChangeRoute,
  handleShowHideRaster
}: AOIRouteInvalidCardProps) => {
  const dispatch = useAppDispatch();
  const isInvalidstatus = aoiRouteDetail.status === AOIStausType.invalid;
  const { selectedProject }: ProjectState = useAppSelector((state) => state.projects);
  const isViewer = selectedProject?.user_permission === UserPermission.Viewer;
  const requestedUser = aoiRouteDetail.requested_by ? getMember(aoiRouteDetail.requested_by) : null;

  const handleClickMenu = async ({ key }: any) => {
    if (title === 'AOI') {
      if (key === 'edit') {
        // @ts-ignore
        handleEditAOI(aoiRouteDetail);
      } else if (key === 'download') {
        await geoJsonTOKmlDownloadFile(aoiRouteDetail, aoiRouteDetail.index, title);
      }
    } else if (title === 'Route') {
      if (key === 'edit') {
        // @ts-ignore
        handleEditRoute(aoiRouteDetail);
      } else if (key === 'download') {
        await geoJsonTOKmlDownloadFile(aoiRouteDetail, aoiRouteDetail.index, 'Route');
        if (!isEmpty(aoiRouteDetail.towers)) {
          // @ts-ignore
          await geoJsonTOKmlTowerDownloadFile(aoiRouteDetail, aoiRouteDetail.index, 'Route-Tower');
        }
        getAsyncStorageValue(ACCESS_TOKEN).then((token: string) => {
          dispatch(downloadRouteDetails(aoiRouteDetail.id, token));
        });
      }
    }
  };

  const handleChangeAOIRoute = () => {
    if (title === 'AOI') {
      handleChangeAOI(aoiRouteDetail.id);
    } else if (title === 'Route') {
      // @ts-ignore
      handleChangeRoute(aoiRouteDetail);
    }
  };

  return (
    <Card
      className={title === 'AOI' ? 'aoi-card' : 'route-card'}
      style={{
        border: `1px solid ${isInvalidstatus ? lightred1 : greyShed5}`,
        backgroundColor: selectedAoiRouteId === aoiRouteDetail.id ? '#d6000033' : white
      }}
      data-attr={aoiRouteDetail.color}>
      <div
        className="inr-content-col"
        style={{ backgroundColor: isInvalidstatus ? lightred1 : greyShed6 }}
      />
      <Row className="inner-card-content">
        <Col
          span={17}
          className="inner-card-content-col"
          onClick={() => (isEdit ? () => {} : handleChangeAOIRoute())}>
          <div>
            <Text className="aoi-title">
              {title} {aoiRouteDetail.index}
            </Text>
            {title === 'Route' && (
              <Text>
                (
                {aoiRouteDetail.geometry
                  ? `${(
                      getLength(
                        new LineString(
                          aoiRouteDetail.geometry.coordinates.map((point: any) => fromLonLat(point))
                        )
                      ) / 1e3
                    ).toFixed(2)} km`
                  : ''}
                )
              </Text>
            )}
            {title === 'AOI' && (
              <Text>
                ({aoiRouteDetail.geometry ? `${getAOIArea(aoiRouteDetail.geometry)} sq km` : ''})
              </Text>
            )}
          </div>
          {requestedUser && (
            <Text className="upload-name">
              {
                // @ts-ignore
                aoiRouteDetail?.parent_aoi_id ? 'Edited by' : 'Generated by'
              }
              <Text> {requestedUser?.label}</Text>
            </Text>
          )}
        </Col>
        <Col span={5} className="inner-card-content-icon aoi-invalid-icon">
          <InvalidIcon height={18} width={18} />
          {
            // @ts-ignore
          }
          <Text
            onClick={() =>
              // @ts-ignore
              handleShowHideRaster(aoiRouteDetail)
            }>
            {AOIRouteRasterData?.id! === aoiRouteDetail.id ? (
              <RasterEnabledIcon />
            ) : (
              <RasterDisabledIcon />
            )}
          </Text>
          <Dropdown
            placement="bottomRight"
            overlay={
              <Menu onClick={handleClickMenu}>
                <Menu.Item key="edit" disabled={isViewer}>
                  Edit
                </Menu.Item>
                <Menu.Item key="download">Download</Menu.Item>
              </Menu>
            }
            trigger={['click']}
            overlayClassName="maplayer-dropdown">
            <VerticalThreeDotIcon height={16} width={6} />
          </Dropdown>
        </Col>
      </Row>
    </Card>
  );
};
AOIRouteInvalidCard.defaultProps = {
  isEdit: false
};

export default AOIRouteInvalidCard;
