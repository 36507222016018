import { IconProps } from 'types/common.types';

const PlusIcon = ({ color, height, width, onClick }: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      onClick={onClick}
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M7.00017 1.79956V6.99956M7.00017 12.1996V6.99956M7.00017 6.99956H12.2002H1.80017"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
PlusIcon.defaultProps = {
  color: '#C8C8C8',
  height: 14,
  width: 14
};

export default PlusIcon;
