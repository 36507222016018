import { Card, Col, Dropdown, Menu, Row, Typography } from 'antd';
import VerticalThreeDotIcon from 'assets/svg/VerticalThreeDotIcon';
import RasterEnabledIcon from 'assets/svg/RasterEnabledIcon';
import RasterDisabledIcon from 'assets/svg/RasterDisabledIcon';
import { white } from 'constants/theme.constants';
import { aoiBackColors } from 'constants/common.constant';
import { useAppSelector } from 'hooks/useReduxHook';
import { AOIDetail } from 'store/aoi/types';
import { getMember } from 'store/projects';
import { ProjectState } from 'store/projects/types';
import { UserPermission } from 'store/users/types';
import { geoJsonTOKmlDownloadFile, getAOIArea } from 'utils/util';

const { Text } = Typography;

interface AoiItemCardProps {
  isEditAOI: boolean;
  aoiDetail: AOIDetail;
  selectedAoiIds: string[];
  selectedAoi: AOIDetail | null;
  AOIRasterBufferData: AOIDetail | null;
  handleShowHideSelectedAoiIds: (aoiId: string) => void;
  handleChangeAOI: (aoiId: string) => void;
  handleEditAOI: (aoiId: AOIDetail | null) => void;
  handleShowHideRasterBuffer: (aoiId: AOIDetail | null) => void;
}

const AoiItemCard = ({
  aoiDetail,
  // eslint-disable-next-line
  isEditAOI,
  // eslint-disable-next-line
  selectedAoiIds,
  selectedAoi,
  AOIRasterBufferData,
  // eslint-disable-next-line
  handleShowHideSelectedAoiIds,
  handleEditAOI,
  handleChangeAOI,
  handleShowHideRasterBuffer
}: AoiItemCardProps) => {
  const { selectedProject }: ProjectState = useAppSelector((state) => state.projects);
  const isViewer = selectedProject?.user_permission === UserPermission.Viewer;
  const requestedUser = aoiDetail.requested_by ? getMember(aoiDetail.requested_by) : null;

  const handleClickMenu = async ({ key }: any) => {
    if (key === 'edit') {
      handleEditAOI(aoiDetail);
    } else if (key === 'download') {
      await geoJsonTOKmlDownloadFile(aoiDetail, aoiDetail.index, 'AOI');
    }
  };

  return (
    <Card
      className={`aoi-card ${selectedAoi?.id === aoiDetail.id ? 'aoi-card-active' : ''}`}
      data-attr={aoiDetail.color}
      style={{
        border: `1px solid ${aoiDetail.color}`,
        backgroundColor:
          selectedAoi?.id === aoiDetail.id ? aoiBackColors[`${aoiDetail.color}`] : white
      }}>
      <div className="inr-content-col" style={{ backgroundColor: `${aoiDetail.color}` }} />
      <Row className="inner-card-content">
        <Col
          span={17}
          className="inner-card-content-col"
          onClick={() => handleChangeAOI(aoiDetail.id)}>
          <div>
            <Text className="aoi-title">Aoi {aoiDetail.index}</Text>
            <Text>({aoiDetail.geometry ? `${getAOIArea(aoiDetail.geometry)} sq km` : ''})</Text>
          </div>
          {requestedUser && (
            <Text className="upload-name">
              {aoiDetail.parent_aoi_id ? 'Edited by' : 'Generated by'}
              <Text> {requestedUser?.label}</Text>
            </Text>
          )}
        </Col>
        <Col span={6} className="inner-card-content-icon">
          <div className="raster-edit-div">
            <Text
              disabled={isEditAOI}
              onClick={() => (isEditAOI ? () => {} : handleShowHideRasterBuffer(aoiDetail))}>
              {AOIRasterBufferData?.id! === aoiDetail.id ? (
                <RasterEnabledIcon />
              ) : (
                <RasterDisabledIcon />
              )}
            </Text>
            <Dropdown
              placement="bottomRight"
              overlay={
                <Menu onClick={handleClickMenu}>
                  <Menu.Item key="edit" disabled={isViewer}>
                    Edit
                  </Menu.Item>
                  <Menu.Item key="download">Download</Menu.Item>
                </Menu>
              }
              trigger={['click']}
              overlayClassName="maplayer-dropdown">
              <VerticalThreeDotIcon height={16} width={6} />
            </Dropdown>
          </div>
        </Col>
      </Row>
    </Card>
  );
};

export default AoiItemCard;
