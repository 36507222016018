import { Modal } from 'antd';
import './styles.less';

interface DeleteRouteTowerModalType {
  isOpen: boolean;
  isLoading: boolean;
  handleOkayDeleteRoute: () => void;
  handleCancelDeleteRoute: () => void;
}

const DeleteRouteTowerModal = ({
  isOpen,
  isLoading,
  handleOkayDeleteRoute,
  handleCancelDeleteRoute
}: DeleteRouteTowerModalType) => {
  return (
    <Modal
      title="Delete Tower Point"
      centered
      okText="Yes"
      cancelText="No"
      className="resume-route-gen"
      open={isOpen}
      maskClosable={false}
      okButtonProps={{ loading: isLoading }}
      cancelButtonProps={{ disabled: isLoading }}
      onOk={handleOkayDeleteRoute}
      onCancel={handleCancelDeleteRoute}>
      <div>Are you sure you want to Delete this route tower point?</div>
    </Modal>
  );
};

export default DeleteRouteTowerModal;
