import { primary } from 'constants/theme.constants';
import { IconProps } from 'types/common.types';

const RasterEnabledIcon = ({ color, height, width, onClick }: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 14 14"
      fill="none"
      onClick={onClick}
      xmlns="http://www.w3.org/2000/svg">
      <circle
        cx="7.00002"
        cy="6.9974"
        r="5.83333"
        stroke={color}
        strokeLinecap="round"
        strokeDasharray="2 2"
      />
      <circle cx="7.00004" cy="6.99479" r="2.91667" fill={color} />
    </svg>
  );
};
RasterEnabledIcon.defaultProps = {
  color: primary,
  height: 14,
  width: 14
};

export default RasterEnabledIcon;
