import { IconProps } from 'types/common.types';

const RasterDisabledIcon = ({ color, height, width, onClick }: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 14 14"
      fill="none"
      onClick={onClick}
      xmlns="http://www.w3.org/2000/svg">
      <circle
        cx="7.00008"
        cy="6.9974"
        r="5.83333"
        stroke="#C8C8C8"
        strokeLinecap="round"
        strokeDasharray="2 2"
      />
      <circle cx="7.00016" cy="6.99479" r="2.91667" fill={color} />
      <path d="M12.3535 2.35156L2.35352 12.3516" stroke={color} strokeLinecap="round" />
      <path d="M11.5 2L1.5 12" stroke="white" strokeLinecap="round" />
    </svg>
  );
};
RasterDisabledIcon.defaultProps = {
  color: '#C8C8C8',
  height: 14,
  width: 14
};

export default RasterDisabledIcon;
