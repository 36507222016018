import { lightred } from 'constants/theme.constants';
import { IconProps } from 'types/common.types';

const DeleteIcon = ({ color, height, width, onClick }: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 10 10"
      fill="none"
      onClick={onClick}
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.16659 6.66927C4.16659 6.89844 3.97909 7.08594 3.74992 7.08594C3.52075 7.08594 3.33325 6.89844 3.33325 6.66927V5.0026C3.33325 4.77344 3.52075 4.58594 3.74992 4.58594C3.97909 4.58594 4.16659 4.77344 4.16659 5.0026V6.66927ZM6.66658 6.66927C6.66658 6.89844 6.47909 7.08594 6.24992 7.08594C6.02075 7.08594 5.83325 6.89844 5.83325 6.66927V5.0026C5.83325 4.77344 6.02075 4.58594 6.24992 4.58594C6.47909 4.58594 6.66658 4.77344 6.66658 5.0026V6.66927ZM7.49992 7.91927C7.49992 8.14885 7.31325 8.33594 7.08325 8.33594H2.91659C2.68659 8.33594 2.49992 8.14885 2.49992 7.91927V3.33594H7.49992V7.91927ZM4.16659 1.80594C4.16659 1.74135 4.25575 1.66927 4.37492 1.66927H5.62492C5.74409 1.66927 5.83325 1.74135 5.83325 1.80594V2.5026H4.16659V1.80594ZM8.74992 2.5026H8.33325H6.66659V1.80594C6.66659 1.27094 6.1995 0.835938 5.62492 0.835938H4.37492C3.80034 0.835938 3.33325 1.27094 3.33325 1.80594V2.5026H1.66659H1.24992C1.02075 2.5026 0.833252 2.6901 0.833252 2.91927C0.833252 3.14844 1.02075 3.33594 1.24992 3.33594H1.66659V7.91927C1.66659 8.60844 2.22742 9.16927 2.91659 9.16927H7.08325C7.77242 9.16927 8.33325 8.60844 8.33325 7.91927V3.33594H8.74992C8.97909 3.33594 9.16659 3.14844 9.16659 2.91927C9.16659 2.6901 8.97909 2.5026 8.74992 2.5026Z"
        fill={color}
      />
    </svg>
  );
};
DeleteIcon.defaultProps = {
  color: lightred,
  height: 10,
  width: 10
};

export default DeleteIcon;
