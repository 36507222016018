import { Modal } from 'antd';
import './styles.less';

interface ResumeRoutGenerationModalType {
  isOpen: boolean;
  isLoading: boolean;
  handleOkayResumeRoute: () => void;
  handleCancelResumeRoute: () => void;
}

const ResumeRoutGenerationModal = ({
  isOpen,
  isLoading,
  handleOkayResumeRoute,
  handleCancelResumeRoute
}: ResumeRoutGenerationModalType) => {
  return (
    <Modal
      title="Resume Route Generation"
      centered
      okText="Continue"
      cancelText="Cancel"
      className="resume-route-gen"
      open={isOpen}
      maskClosable={false}
      okButtonProps={{ loading: isLoading }}
      cancelButtonProps={{ disabled: isLoading }}
      onOk={handleOkayResumeRoute}
      onCancel={handleCancelResumeRoute}>
      <div>
        Are you sure you want to save the changes and continue the automatic route generation from
        the last tower (GNT)?
      </div>
    </Modal>
  );
};

export default ResumeRoutGenerationModal;
