import { Button, Select as AntdSelect, Typography } from 'antd';
import { find } from 'lodash';
import CloseIcon from 'assets/svg/CloseIcon';
import DeleteIcon from 'assets/svg/DeleteIcon';
import LocationPinIcon from 'assets/svg/LocationPinIcon';
import PlusIcon from 'assets/svg/PlusIcon';
import TowerIcon from 'assets/svg/TowerIcon';
import PlayIcon from 'assets/svg/PlayIcon';
import { red } from 'constants/theme.constants';
import { useAppSelector } from 'hooks/useReduxHook';
import { getTowerTypeOptions } from 'store/route';
import { TowerType } from 'store/route/types';

const { Text } = Typography;

interface AddnewGntPointPropstypes {
  towers: TowerType[] | [];
  newTowers: TowerType[] | [];
  gntTowerData: {
    coordinates: number[];
    type: string;
    name: string;
    deviation_angle: number;
  } | null;
  handleClosePopUp: () => void;
  handleDrawmode: () => void;
  handleShowResumeRoute: () => void;
  handleDeletePoint: () => void;
}

const AddnewGntPoint = ({
  gntTowerData,
  towers,
  newTowers,
  handleClosePopUp,
  handleShowResumeRoute,
  handleDeletePoint,
  handleDrawmode
}: AddnewGntPointPropstypes) => {
  const towerOptions = useAppSelector((state) => getTowerTypeOptions(state));
  const gntOldTower = towers[towers.length - 1] || null;
  const gntNewTower = newTowers[newTowers.length - 1] || null;

  const isSameGntTower =
    gntOldTower?.geometry?.coordinates[0] === gntNewTower?.geometry?.coordinates[0] &&
    gntOldTower?.geometry?.coordinates[1] === gntNewTower?.geometry?.coordinates[1];

  return (
    <div className="line-new-point-popup line-gnt-point-popup">
      {gntTowerData && (
        <div className="modal-title-new-point">
          <div>
            <LocationPinIcon />
            <Text className="tower-name">{gntTowerData.name}</Text>
            <Text className="tower-coord">
              {`(${[
                gntTowerData.coordinates[1].toFixed(4),
                gntTowerData.coordinates[0].toFixed(4)
              ].toString()})`}
            </Text>
          </div>
          <CloseIcon onClick={handleClosePopUp} />
        </div>
      )}
      <div>
        <div>
          <TowerIcon />
          <AntdSelect
            showArrow
            className="tower-type"
            onChange={() => {}}
            aria-readonly
            style={{ width: 75 }}
            value={find(towerOptions, ['label', 'Gantry'])}
            options={towerOptions}
            popupClassName="tower-type-select-popup"
            placeholder="Select Type"
          />
        </div>
      </div>
      <div className="line-new-btns gnt-confirm-add-end-btn">
        <div>
          <Button className="delete-btn" onClick={handleDeletePoint} type="text">
            <DeleteIcon color={red} height={18} width={18} />
          </Button>
          <Button
            onClick={handleDrawmode}
            icon={<PlusIcon />}
            className="add-new-tower"
            type="link">
            Tower
          </Button>
          {!isSameGntTower && (
            <Button
              className="resume-btn"
              onClick={handleShowResumeRoute}
              icon={<PlayIcon />}
              type="link">
              Resume
            </Button>
          )}
        </div>
        <div style={{ fontSize: 9, color: '#95969d' }}>
          Note:Double click on map to place new tower
        </div>
      </div>
    </div>
  );
};

export default AddnewGntPoint;
