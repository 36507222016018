import { useEffect } from 'react';
import WebGLTile from 'ol/layer/WebGLTile';
import { transformExtent } from 'ol/proj';
import GeoTIFF from 'ol/source/GeoTIFF';
import { rasterlayerBandColor } from 'constants/common.constant';
// import proj4 from 'proj4';
// import { register } from 'ol/proj/proj4';
import { useMap } from './MapContext';

// proj4.defs('EPSG:32642', '+proj=utm +zone=18 +datum=WGS84 +units=m +no_defs +type=crs');
// register(proj4);

const GeoTIFFCOGLayer = ({ rasterData }: any) => {
  const map = useMap(); // Retrieve the map instance from context

  useEffect(() => {
    if (!map) return;

    if (rasterData && rasterData.master_raster_url) {
      const cogSource = new GeoTIFF({
        sources: [
          {
            url: rasterData.master_raster_url,
            nodata: -9999
          }
        ],
        wrapX: false,
        normalize: false,
        interpolate: false
        // projection: 'EPSG:32642'
      });

      cogSource.getView().then((options) => {
        return map
          .getView()
          .fit(
            transformExtent(options?.extent!, options.projection, map.getView().getProjection())
          );
      });

      // Create a TileLayer using XYZ source for COG
      const tileLayer = new WebGLTile({
        source: cogSource,
        style: {
          color: rasterlayerBandColor
        }
      });

      map.addLayer(tileLayer);

      // eslint-disable-next-line
      return () => {
        map.removeLayer(tileLayer);
      };
    }
  }, [map, rasterData]);

  return null;
};

export default GeoTIFFCOGLayer;
