/* eslint-disable  */
import { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { cloneDeep, find, findIndex, isEmpty, isFinite, maxBy, orderBy, set, uniq } from 'lodash';
import axios from 'axios';
import { Button, Spin } from 'antd';
import { AiOutlineLoading } from 'react-icons/ai';
import VectorSource from 'ol/source/Vector';
import { fromLonLat, toLonLat } from 'ol/proj';
import { Feature } from 'ol';
import { LineString, Point } from 'ol/geom';
import { ModifyEvent } from 'ol/interaction/Modify';
import { boundingExtent } from 'ol/extent';
import { Draw, Modify, Select } from 'ol/interaction';
import { Fill, Stroke, Style, Text as OlText } from 'ol/style';
import VectorLayer from 'ol/layer/Vector';
import { getDistance } from 'ol/sphere';
import CircleStyle from 'ol/style/Circle';
import { click } from 'ol/events/condition';
import UndoIconV1 from 'assets/svg/UndoIconV1';
import CloseIcon from 'assets/svg/CloseIcon';
import { lightred, primary, white } from 'constants/theme.constants';
import { ACCESS_TOKEN } from 'constants/common.constant';
import ResumeRoutGenerationModal from 'components/Modal/ResumeRoutGenerationModal';
import DeleteRouteTowerModal from 'components/Modal/DeleteRouteTowerModal';
import { useAppDispatch, useAppSelector } from 'hooks/useReduxHook';
import { EditRouteTowerPayloadType, RouteDetail, RouteState, TowerType } from 'store/route/types';
import { getDeviationAngleData } from 'store/route/actions';
import {
  setLineRouteCoords,
  setLineRouteLonLatCoords,
  setLineRoutePrevCoords,
  setLineRouteTowerPayload,
  setLineTowerDetails
} from 'store/route';
import { getAsyncStorageValue } from 'utils/localStorage';
import { GET_TOWER_DEVIATION_ANGLE_URL } from 'utils/apiUrls';
import AddNewPointPopUp from './AddNewPointPopUp';
import { useMap } from './MapContext';
import AddnewGntPoint from './AddnewGntPoint';

interface EditRouteLayerV2PropsType {
  isShowTowerText: boolean;
  editedRoute: RouteDetail | null;
  handleSaveEditRouteCoordsData: Function;
  handleEditRoute: Function;
  handleEditSaveAOIRoutebtn: (isBool: boolean) => void;
  handleDrawModeGntPoint: (isBool: boolean) => void;
  handleConfirmResumeRoute: Function;
  handleSaveDrawRoute: Function;
}

const EditRouteLayerV2 = ({
  isShowTowerText,
  editedRoute,
  // eslint-disable-next-line
  handleSaveEditRouteCoordsData,
  handleConfirmResumeRoute,
  handleEditRoute,
  handleEditSaveAOIRoutebtn,
  handleDrawModeGntPoint,
  handleSaveDrawRoute
}: EditRouteLayerV2PropsType) => {
  const map = useMap();

  const dispatch = useAppDispatch();
  const {
    towerTypes,
    lineTowerDetails,
    lineRouteCoords,
    lineRouteTowerPayload,
    lineRouteLonLatCoords,
    lineRoutePrevCoords,
    isRequestingResumeRoute
  }: RouteState = useAppSelector((state) => state.route);

  // @ts-ignore
  const vectorLayerRef = useRef<VectorLayer>();
  const selectInteractionRef = useRef<Select>();
  const modifyInteractionRef = useRef<Modify>();
  const vectorSourceRef = useRef<VectorSource>();
  const [loading, setLoading] = useState<boolean>(false);
  const [isModifyTower, setModifyTower] = useState<boolean>(false);
  const [isDeleteTower, setDeleteTower] = useState<boolean>(false);
  const [deleteTowerFeature, setDeleteTowerFeature] = useState<any>(null);
  const [maxTowerId, setMaxTowerId] = useState<number | null>(null);
  const [drawMode, setDrawMode] = useState<boolean>(false);
  const [isResumeTowerModal, setResumeTowerModal] = useState<boolean>(false);
  const [isDeleteTowerModal, setDeleteTowerModal] = useState<boolean>(false);
  const [isUndoTower, setUndoTower] = useState<boolean>(false);
  const [undoTowerData, setUndoTowerData] = useState<any | null>(null);
  const [changeTowerData, setChangeTowerData] = useState<{
    type: string;
    towerName: string;
    deviation_angle: number;
  } | null>(null);
  const [changeTowerCoord, setChangeTowerCoord] = useState<string | null>(null);
  const [gntTowerData, setGntTowerData] = useState<{
    coordinates: number[];
    type: string;
    name: string;
    deviation_angle: number;
  } | null>(null);
  const [prevPointData, setPrevPointData] = useState<any>(null);
  const [nextPointData, setNextPointData] = useState<any>(null);
  const [isShowDrawModeSaveEndRouteTitle, setDrawModeSaveEndRouteTitle] = useState<boolean>(false);
  const [isShowDrawModeSaveEndRoute, setDrawModeSaveEndRoute] = useState<boolean>(false);
  const [count, setCount] = useState<number>(0);

  const handleConfirmEditRoute = () => {
    // handleEditSaveAOIRoutebtn(false);
    // setDeleteTower(false);
    setPrevPointData(null);
    setNextPointData(null);
  };

  const handleDeletePoint = () => {
    setDeleteTowerModal(true);
  };

  const handleCancelDeleteRoute = () => {
    setDeleteTowerModal(false);
  };

  const handleOkayDeleteRoute = () => {
    if (deleteTowerFeature) {
      if (vectorSourceRef.current) {
        let routeLineCoordsData = cloneDeep([...lineRouteCoords]);
        let routeLineCoordsLonLatData = cloneDeep([...lineRouteLonLatCoords]);
        let routeLineTowersPayloadData = cloneDeep([...lineRouteTowerPayload]);
        let linetowers = cloneDeep([...lineTowerDetails]);

        linetowers.splice(deleteTowerFeature.get('index'), 1);
        routeLineCoordsData.splice(deleteTowerFeature.get('index'), 1);
        routeLineCoordsLonLatData.splice(deleteTowerFeature.get('index'), 1);
        routeLineTowersPayloadData.splice(deleteTowerFeature.get('index'), 1);
        if (
          deleteTowerFeature.get('towertype') === 'Gantry' &&
          deleteTowerFeature.get('index') > 0
        ) {
          const prevPointIndex = deleteTowerFeature.get('index') - 1;

          linetowers.splice(linetowers.length - 1, 1, {
            id: linetowers[prevPointIndex].id,
            type: 'Gantry',
            deviation_angle: 0,
            name: 'GNT',
            route_id: linetowers[0].route_id,
            geometry: {
              coordinates: linetowers[prevPointIndex].geometry.coordinates,
              type: 'Point'
            }
          });
          routeLineTowersPayloadData.splice(linetowers.length - 1, 1, {
            index: prevPointIndex,
            type_id: null,
            coordinates: linetowers[prevPointIndex].geometry.coordinates,
            deviation_angle: 0
          });
        }

        dispatch(setLineRouteCoords(routeLineCoordsData));
        dispatch(setLineRouteLonLatCoords(routeLineCoordsLonLatData));
        dispatch(setLineRouteTowerPayload(routeLineTowersPayloadData));
        dispatch(setLineTowerDetails(linetowers));
        vectorSourceRef.current.removeFeature(deleteTowerFeature);

        setDeleteTowerFeature(null);
        setGntTowerData(null);
        setDeleteTower(false);
        setModifyTower(false);
        handleCancelDeleteRoute();
      }
    }
  };

  const handleUndoAddnewGntData = () => {};

  const handleClosePopUp = () => {
    setModifyTower(false);
    setDrawMode(false);
    setGntTowerData(null);
    setDeleteTowerFeature(null);
    setDeleteTower(false);
    setUndoTower(false);
    setChangeTowerCoord(null);
    setChangeTowerData(null);
    setPrevPointData(null);
    handleDrawModeGntPoint(false);
    setDrawModeSaveEndRoute(false);
    setNextPointData(null);
    // handleEditSaveAOIRoutebtn(true);
  };

  // const handleDrawModeRouteClose = () => {
  // };

  useLayoutEffect(() => {
    if (editedRoute) {
      const coords = [...orderBy(editedRoute.towers, 'id')].map((t: TowerType) =>
        fromLonLat(t.geometry.coordinates)
      );
      const lonlatCoords = [...orderBy(editedRoute.towers, 'id')].map(
        (t: TowerType) => t.geometry.coordinates
      );

      setMaxTowerId(maxBy([...orderBy(editedRoute.towers, 'id')], 'id')?.id || null);
      const payload: EditRouteTowerPayloadType[] = [...orderBy(editedRoute.towers, 'id')].map(
        (t: TowerType, index: number) => {
          return {
            coordinates: t.geometry.coordinates,
            type_id: find(towerTypes, ['name', t.type])?.id || null,
            index,
            deviation_angle: Number(t.deviation_angle)
          };
        }
      );

      dispatch(setLineTowerDetails([...orderBy(editedRoute.towers, 'id')]));
      dispatch(setLineRouteCoords([...coords]));
      dispatch(setLineRouteLonLatCoords([...lonlatCoords]));
      dispatch(setLineRoutePrevCoords([...lonlatCoords]));
      dispatch(setLineRouteTowerPayload([...payload]));
    }
  }, [editedRoute]);

  useEffect(() => {
    vectorSourceRef.current = new VectorSource();
    vectorLayerRef.current = new VectorLayer({
      source: vectorSourceRef.current,
      style: (feature) => {
        const color = feature.get('fillColor') || 'blue';
        if (feature.getGeometry() instanceof Point) {
          return new Style({
            image: new CircleStyle({
              radius: 6,
              fill: new Fill({ color }),
              stroke: new Stroke({ color })
            }),
            zIndex: 110
          });
        }
        return new Style({
          stroke: new Stroke({
            color,
            width: 2,
            lineDash: [5, 10]
          })
        });
      }
    });

    if (map) {
      map.on('click', () => {
        selectInteractionRef.current?.getFeatures().clear();
      });
      map.addLayer(vectorLayerRef.current);
    }

    return () => {
      if (map) {
        map.removeLayer(vectorLayerRef.current);
        modifyInteractionRef.current = undefined;
        selectInteractionRef.current?.getFeatures().clear();
      }
    };
  }, [map]);

  // line render
  useEffect(() => {
    if (map && editedRoute && !isEmpty(lineRouteCoords)) {
      const lineStringFeature = new Feature({
        geometry: new LineString([...lineRouteCoords]),
        fillColor: editedRoute?.color!,
        zIndex: 100
      });

      lineStringFeature.setStyle(
        new Style({
          stroke: new Stroke({
            color: editedRoute?.color,
            width: 1
          })
        })
      );
      lineStringFeature.set('id', 'mainlinestring');
      vectorSourceRef.current?.addFeature(lineStringFeature);

      map.render();
    }
    return () => {
      vectorSourceRef.current?.clear();
    };
  }, [map, lineRouteCoords, count, isShowTowerText]);

  // point render
  useEffect(() => {
    if (map && editedRoute && !isEmpty(lineRouteLonLatCoords)) {
      // eslint-disable-next-line
      [...lineRouteLonLatCoords].forEach((coord, i) => {
        const fromlonglatcoord = fromLonLat(coord);

        const labelFeature = new Feature({
          geometry: new Point(fromlonglatcoord),
          zIndex: 110
        });

        const tower = [...lineTowerDetails].find((tower) => {
          return (
            Number(tower.geometry.coordinates[0].toFixed(8)) === Number(coord[0].toFixed(8)) &&
            Number(tower.geometry.coordinates[1].toFixed(8)) === Number(coord[1].toFixed(8))
          );
        });

        labelFeature.setId(`tower-point-${tower?.id}-${i}-${tower?.type}-${tower?.name}`);
        labelFeature.set('towertype', tower?.type);
        labelFeature.set('deviationAngle', tower?.deviation_angle);
        labelFeature.set('name', tower?.name);
        labelFeature.set('towerid', tower?.id);
        labelFeature.set('index', i);
        const geometry = labelFeature.getGeometry();
        if (geometry instanceof Point) {
          let extent = geometry.getExtent();
          if (!extent || extent.some((value) => !isFinite(value))) {
            const coordinates = geometry.getCoordinates();
            const buffer = 1e-5;
            extent = boundingExtent([
              [coordinates[0] - buffer, coordinates[1] - buffer],
              [coordinates[0] + buffer, coordinates[1] + buffer]
            ]);
          }
          labelFeature.set('fixedExtent', extent);
        }
        const zoom = map?.getView().getZoom() || 0;
        const fontSize = Math.max(8, 30 - zoom * 2);
        labelFeature.setStyle(
          new Style({
            text: isShowTowerText
              ? new OlText({
                  text: tower?.name || '',
                  font: `${fontSize}px`,
                  fill: new Fill({ color: editedRoute?.color }),
                  offsetY: -10,
                  offsetX: i % 2 === 0 ? 50 : -10
                })
              : undefined,
            image: new CircleStyle({
              radius: 6,
              fill: new Fill({ color: editedRoute.color }),
              stroke: new Stroke({ color: editedRoute.color })
            }),
            zIndex: 110
          })
        );

        vectorSourceRef.current?.addFeature(labelFeature);
      });

      map.render();
    }
    return () => {
      vectorSourceRef.current?.clear();
    };
  }, [map, lineRouteCoords, lineTowerDetails, isShowTowerText, count]);

  // eslint-disable-next-line
  useEffect(() => {
    setLoading(true);
    if (map && !isEmpty(lineRouteCoords) && editedRoute) {
      handleEditSaveAOIRoutebtn(false);
      setDeleteTower(false);

      const modify = new Modify({
        source: vectorSourceRef.current
      });
      modifyInteractionRef.current = modify;
      map.addInteraction(modify);

      // modify.on('modifystart', (event: ModifyEvent) => {
      //   event.features.forEach((feature) => {
      //     const modifiedFeatures = event.features.getArray();
      //     // setUndoTower(true);
      //     const modifyPointFeatures = // @ts-ignore
      //       modifiedFeatures.filter((feature) => feature.getGeometry().getType() === 'Point');
      //     const modifyLinestringFeatures = // @ts-ignore
      //       modifiedFeatures.filter((feature) => feature.getGeometry().getType() === 'LineString');

      //     if (feature.getGeometry() instanceof LineString) {
      //       // @ts-ignore
      //       const coords = feature.getGeometry()?.getCoordinates();
      //     }
      //     if (feature.getGeometry() instanceof Point) {
      //       // @ts-ignore
      //       const coord = feature.getGeometry()?.getCoordinates();
      //       const type = feature.get('towertype');
      //       const angle = feature.get('deviationAngle');
      //       const name = feature.get('name');
      //       const ind = feature.get('index');
      //       const towerid = feature.get('towerid');

      //       if (undoTowerData === null) {
      //         const prevtowerData = {
      //           name,
      //           id: towerid,
      //           index: ind,
      //           deviation_angle: [String(angle)],
      //           type: [type],
      //           pointcoords: [toLonLat(coord)]
      //         };
      //         setUndoTowerData(prevtowerData);
      //       } else if (undoTowerData && name === undoTowerData.name) {
      //         const preData = { ...undoTowerData };

      //         const updateAngle = [...preData.deviation_angle].concat([String(angle)]);
      //         const updatetype = [...preData.type].concat([type]);
      //         const updateCoords = [...preData.pointcoords].concat([toLonLat(coord)]);
      //         setUndoTowerData({
      //           ...preData,
      //           pointcoords: updateCoords,
      //           deviation_angle: updateAngle,
      //           type: updatetype
      //         });
      //       }
      //     }
      //   });
      // });

      modify.on('modifyend', (event: ModifyEvent) => {
        setPrevPointData(null);
        setNextPointData(null);
        setDeleteTower(false);
        setDrawModeSaveEndRoute(false);
        handleDrawModeGntPoint(false);
        const modifiedFeatures = event.features.getArray();
        // setUndoTower(true);
        const modifyPointFeatures = // @ts-ignore
          modifiedFeatures.filter((feature) => feature.getGeometry().getType() === 'Point');
        const modifyLinestringFeatures = // @ts-ignore
          modifiedFeatures.filter((feature) => feature.getGeometry().getType() === 'LineString');

        setModifyTower(true);
        // linestring
        if (modifyLinestringFeatures?.length > 0) {
          const newVertexLineCoordinates = modifyLinestringFeatures[0]
            ?.getGeometry()
            // @ts-ignore
            ?.getCoordinates();

          if (newVertexLineCoordinates.length > lineRouteCoords?.length) {
            setCount(count + 1);
            const differentData: any = [];

            // Compare each element in updatedData with data
            newVertexLineCoordinates.forEach((coord: any) => {
              const found = cloneDeep([...lineRouteCoords]).some(
                (d) => d[0] === coord[0] && d[1] === coord[1]
              );
              if (!found) {
                differentData.push(coord);
              }
            });

            const pointIndex = newVertexLineCoordinates.findIndex(
              (data: any) => data[0] === differentData[0][0] && data[1] === differentData[0][1]
            );
            const prevpointindex = pointIndex - 1;
            const nextpointindex = pointIndex + 1;

            const newPoint = newVertexLineCoordinates[pointIndex];
            const prevPoint = newVertexLineCoordinates[pointIndex - 1];
            const nextPoint = newVertexLineCoordinates[pointIndex + 1];

            let oldData = cloneDeep([...lineRouteTowerPayload]);
            let updateTowerDatas = cloneDeep([...lineTowerDetails]);

            const prevtower = updateTowerDatas.find((tower) => {
              return (
                Number(tower.geometry.coordinates[0].toFixed(8)) ===
                  Number(toLonLat(prevPoint)[0].toFixed(8)) &&
                Number(tower.geometry.coordinates[1].toFixed(8)) ===
                  Number(toLonLat(prevPoint)[1].toFixed(8))
              );
            });

            const nexttower = updateTowerDatas.find((tower) => {
              return (
                Number(tower.geometry.coordinates[0].toFixed(8)) ===
                  Number(toLonLat(nextPoint)[0].toFixed(8)) &&
                Number(tower.geometry.coordinates[1].toFixed(8)) ===
                  Number(toLonLat(nextPoint)[1].toFixed(8))
              );
            });

            let data: any = [];

            if (prevtower?.type === 'Gantry') {
              const nextData: any = {
                currentCoord: toLonLat(nextPoint),
                prevCoord: toLonLat(newPoint),
                nextCoord: toLonLat(newVertexLineCoordinates[pointIndex + 2])
              };

              const newData: any = {
                currentCoord: toLonLat(newPoint),
                prevCoord: toLonLat(prevPoint),
                nextCoord: toLonLat(nextPoint)
              };

              data = [
                { pointData: newData, pointType: 'new' },
                { pointData: nextData, pointType: 'next' }
              ];
            } else if (nexttower?.type === 'Gantry') {
              const prevData: any = {
                currentCoord: toLonLat(prevPoint),
                prevCoord: toLonLat(newVertexLineCoordinates[pointIndex - 2]),
                nextCoord: toLonLat(newPoint)
              };
              const newData: any = {
                currentCoord: toLonLat(newPoint),
                prevCoord: toLonLat(prevPoint),
                nextCoord: toLonLat(nextPoint)
              };
              data = [
                { pointData: prevData, pointType: 'prev' },
                { pointData: newData, pointType: 'new' }
              ];
            } else {
              const prevData: any = {
                currentCoord: toLonLat(prevPoint),
                prevCoord: toLonLat(newVertexLineCoordinates[pointIndex - 2]),
                nextCoord: toLonLat(newPoint)
              };

              const nextData: any = {
                currentCoord: toLonLat(nextPoint),
                prevCoord: toLonLat(newPoint),
                nextCoord: toLonLat(newVertexLineCoordinates[pointIndex + 2])
              };

              const newData: any = {
                currentCoord: toLonLat(newPoint),
                prevCoord: toLonLat(prevPoint),
                nextCoord: toLonLat(nextPoint)
              };
              data = [
                { pointData: prevData, pointType: 'prev' },
                { pointData: newData, pointType: 'new' },
                { pointData: nextData, pointType: 'next' }
              ];
            }

            getAsyncStorageValue(ACCESS_TOKEN).then(async (token: string) => {
              const promises = data.map((d: any) => {
                return axios
                  .get(
                    GET_TOWER_DEVIATION_ANGLE_URL(
                      d.pointData.prevCoord.toString(),
                      d.pointData.currentCoord.toString(),
                      d.pointData.nextCoord.toString()
                    ),
                    {
                      headers: {
                        'Content-Type': 'application/json',
                        accept: 'application/json',
                        Authorization: token
                      }
                    }
                  )
                  .then((resp) => resp.data)
                  .then((res) => res.data);
              });

              const apiFinalRes = await Promise.all(promises).then((res) => {
                return res;
              });

              if (!isEmpty(apiFinalRes)) {
                selectInteractionRef.current?.getFeatures().clear();

                let iprevtower: any = null;
                let inexttower: any = null;

                const ind = findIndex(
                  updateTowerDatas,
                  (tower) =>
                    tower.geometry.coordinates[0].toFixed(8) ===
                      toLonLat(nextPoint)[0].toFixed(8) &&
                    tower.geometry.coordinates[1].toFixed(8) === toLonLat(nextPoint)[1].toFixed(8)
                );

                const indpayload = findIndex(
                  oldData,
                  (tower) =>
                    tower.coordinates[0].toFixed(8) === toLonLat(nextPoint)[0].toFixed(8) &&
                    tower.coordinates[1].toFixed(8) === toLonLat(nextPoint)[1].toFixed(8)
                );

                const towername = `AP-${newVertexLineCoordinates.length - 1}`;

                let updatedTowerData: any = null;

                let towerPayloadData: any = null;

                if (prevtower?.type === 'Gantry') {
                  iprevtower = {
                    deviation_angle: 0,
                    geometry: { coordinates: toLonLat(prevPoint), type: 'Point' },
                    route_id: prevtower?.route_id,
                    id: prevtower?.id,
                    index: prevpointindex,
                    type: 'Gantry',
                    name: 'GNT'
                  };
                  // prev
                  updateTowerDatas[prevpointindex].type = 'Gantry';
                  updateTowerDatas[prevpointindex].deviation_angle = 0;
                  oldData[prevpointindex].type_id = null;
                  oldData[prevpointindex].deviation_angle = 0;

                  // next
                  updateTowerDatas[ind].type = apiFinalRes[1].type;
                  updateTowerDatas[ind].deviation_angle = String(apiFinalRes[1].deviation_angle);
                  oldData[indpayload].type_id =
                    find(towerTypes, ['name', apiFinalRes[1].type])?.id || null;
                  oldData[indpayload].deviation_angle = apiFinalRes[1].deviation_angle;

                  updatedTowerData = {
                    id: maxTowerId! + 1,
                    route_id: updateTowerDatas[0].route_id,
                    type: apiFinalRes[0].type,
                    deviation_angle: String(apiFinalRes[0].deviation_angle),
                    name: towername,
                    geometry: {
                      type: 'Point',
                      coordinates: toLonLat(newPoint)
                    }
                  };

                  towerPayloadData = {
                    coordinates: toLonLat(newPoint),
                    type_id: find(towerTypes, ['name', apiFinalRes[0].type])?.id || null,
                    index: pointIndex,
                    deviation_angle: Number(apiFinalRes[0].deviation_angle)
                  };

                  setChangeTowerData({
                    towerName: towername,
                    type: apiFinalRes[0].type,
                    deviation_angle: Number(apiFinalRes[0].deviation_angle)
                  });
                } else if (nexttower?.type === 'Gantry') {
                  inexttower = {
                    geometry: { coordinates: toLonLat(nextPoint), type: 'Point' },
                    route_id: nexttower?.route_id,
                    id: nexttower?.id,
                    index: nextpointindex,
                    deviation_angle: 0,
                    type: 'Gantry',
                    name: 'GNT'
                  };
                  // prev
                  updateTowerDatas[prevpointindex].type = apiFinalRes[0].type;
                  updateTowerDatas[prevpointindex].deviation_angle = String(
                    apiFinalRes[0].deviation_angle
                  );
                  oldData[prevpointindex].type_id =
                    find(towerTypes, ['name', apiFinalRes[0].type])?.id || null;
                  oldData[prevpointindex].deviation_angle = apiFinalRes[0].deviation_angle;

                  // next
                  updateTowerDatas[ind].type = 'Gantry';
                  updateTowerDatas[ind].deviation_angle = 0;
                  oldData[indpayload].type_id = null;
                  oldData[indpayload].deviation_angle = 0;

                  updatedTowerData = {
                    id: maxTowerId! + 1,
                    route_id: updateTowerDatas[0].route_id,
                    type: apiFinalRes[0].type,
                    deviation_angle: String(apiFinalRes[0].deviation_angle),
                    name: towername,
                    geometry: {
                      type: 'Point',
                      coordinates: toLonLat(newPoint)
                    }
                  };

                  towerPayloadData = {
                    coordinates: toLonLat(newPoint),
                    type_id: find(towerTypes, ['name', apiFinalRes[0].type])?.id || null,
                    index: pointIndex,
                    deviation_angle: Number(apiFinalRes[0].deviation_angle)
                  };

                  setChangeTowerData({
                    towerName: towername,
                    type: apiFinalRes[0].type,
                    deviation_angle: Number(apiFinalRes[0].deviation_angle)
                  });
                } else {
                  iprevtower = {
                    deviation_angle: apiFinalRes[0].deviation_angle,
                    geometry: { coordinates: toLonLat(prevPoint), type: 'Point' },
                    route_id: prevtower?.route_id,
                    id: prevtower?.id,
                    index: prevpointindex,
                    type: apiFinalRes[0].type,
                    name: prevtower?.name
                  };

                  inexttower = {
                    geometry: { coordinates: toLonLat(nextPoint), type: 'Point' },
                    route_id: nexttower?.route_id,
                    id: nexttower?.id,
                    index: nextpointindex,
                    type: apiFinalRes[2].type,
                    deviation_angle: apiFinalRes[2].deviation_angle,
                    name: nexttower?.name
                  };

                  // prev
                  updateTowerDatas[prevpointindex].type = apiFinalRes[0].type;
                  updateTowerDatas[prevpointindex].deviation_angle = String(
                    apiFinalRes[0].deviation_angle
                  );
                  oldData[prevpointindex].type_id =
                    find(towerTypes, ['name', apiFinalRes[0].type])?.id || null;
                  oldData[prevpointindex].deviation_angle = apiFinalRes[0].deviation_angle;

                  // next
                  updateTowerDatas[ind].type = apiFinalRes[2].type;
                  updateTowerDatas[ind].deviation_angle = String(apiFinalRes[2].deviation_angle);
                  oldData[indpayload].type_id =
                    find(towerTypes, ['name', apiFinalRes[2].type])?.id || null;
                  oldData[indpayload].deviation_angle = apiFinalRes[2].deviation_angle;

                  updatedTowerData = {
                    id: maxTowerId! + 1,
                    route_id: updateTowerDatas[0].route_id,
                    type: apiFinalRes[1].type,
                    deviation_angle: String(apiFinalRes[1].deviation_angle),
                    name: towername,
                    geometry: {
                      type: 'Point',
                      coordinates: toLonLat(newPoint)
                    }
                  };

                  towerPayloadData = {
                    coordinates: toLonLat(newPoint),
                    type_id: find(towerTypes, ['name', apiFinalRes[1].type])?.id || null,
                    index: pointIndex,
                    deviation_angle: Number(apiFinalRes[1].deviation_angle)
                  };

                  setChangeTowerData({
                    towerName: towername,
                    type: apiFinalRes[1].type,
                    deviation_angle: Number(apiFinalRes[1].deviation_angle)
                  });
                }

                setPrevPointData(iprevtower || null);
                setNextPointData(inexttower || null);

                const newvertexlonlatcoords = [
                  ...newVertexLineCoordinates.map((coord: number[]) => toLonLat(coord))
                ];

                updateTowerDatas.splice(pointIndex, 0, updatedTowerData);
                oldData.splice(pointIndex, 0, towerPayloadData);

                const toweruniqdatas = [...newvertexlonlatcoords]
                  .map((coord) => {
                    // Find a matching towerdata object
                    const match = [...updateTowerDatas].find(
                      (tower) =>
                        tower.geometry.coordinates[0].toFixed(8) === coord[0].toFixed(8) &&
                        tower.geometry.coordinates[1].toFixed(8) === coord[1].toFixed(8)
                    );
                    return match || null; // Return the match or null
                  })
                  .filter((item, index, self) => item !== null && self.indexOf(item) === index);

                const towerpayloaduniqdatas = [...newvertexlonlatcoords]
                  .map((coord) => {
                    // Find a matching towerdata object
                    const match = [...oldData].find(
                      (tower) =>
                        tower.coordinates[0].toFixed(8) === coord[0].toFixed(8) &&
                        tower.coordinates[1].toFixed(8) === coord[1].toFixed(8)
                    );
                    return match || null; // Return the match or null
                  })
                  .filter((item, index, self) => item !== null && self.indexOf(item) === index);

                dispatch(setLineRouteCoords([...newVertexLineCoordinates]));
                dispatch(setLineRouteLonLatCoords([...newvertexlonlatcoords]));
                dispatch(setLineTowerDetails(uniq([...toweruniqdatas])));
                dispatch(setLineRouteTowerPayload(uniq([...towerpayloaduniqdatas])));
                setChangeTowerCoord(
                  [toLonLat(newPoint)[1].toFixed(4), toLonLat(newPoint)[0].toFixed(4)].toString()
                );

                handleEditRoute();
              }
            });
          }
        }
        // point
        if (modifyPointFeatures.length > 0) {
          setCount(count + 1);
          // eslint-disable-next-line
          for (const feature of modifyPointFeatures) {
            if (feature.get('index')) {
              const lineCoords = [...lineRouteCoords];
              const pointIndex: number = feature.get('index');
              const prevpointindex = pointIndex - 1;
              const nextpointindex = pointIndex + 1;

              if (pointIndex === 0) return;

              const geometry = feature.getGeometry();
              if (geometry instanceof Point) {
                // @ts-ignore
                const pointcoords = geometry.getCoordinates();

                const currentpoint = toLonLat(pointcoords);

                const prevPoint = lineCoords[pointIndex - 1] || null;
                const nextPoint = lineCoords[pointIndex + 1] || null;

                const prevData: any = {
                  currentCoord: lineCoords[pointIndex - 1],
                  prevCoord: lineCoords[pointIndex - 2],
                  nextCoord: pointcoords
                };

                const nextData: any = {
                  currentCoord: lineCoords[pointIndex + 1],
                  prevCoord: pointcoords,
                  nextCoord: lineCoords[pointIndex + 2]
                };

                const newData: any = {
                  currentCoord: pointcoords,
                  prevCoord: lineCoords[pointIndex - 1],
                  nextCoord: lineCoords[pointIndex + 1]
                };

                const updateTowerData = cloneDeep([...lineTowerDetails]);
                const oldData = cloneDeep([...lineRouteTowerPayload]);
                const currenttower = updateTowerData[pointIndex];
                const prevtowerD = updateTowerData[prevpointindex];
                const nextower = updateTowerData[nextpointindex];
                let data: any = [];
                if (currenttower.type === 'Gantry') {
                  data = [{ pointData: prevData, pointType: 'prev' }];
                } else {
                  data = [
                    { pointData: prevData, pointType: 'prev' },
                    { pointData: newData, pointType: 'new' },
                    { pointData: nextData, pointType: 'next' }
                  ];
                  if (!lineCoords[pointIndex - 2]) {
                    data = [
                      { pointData: newData, pointType: 'new' },
                      { pointData: nextData, pointType: 'next' }
                    ];
                  }
                  if (!lineCoords[pointIndex + 2]) {
                    data = [
                      { pointData: prevData, pointType: 'prev' },
                      { pointData: newData, pointType: 'new' }
                    ];
                  }
                }

                getAsyncStorageValue(ACCESS_TOKEN).then(async (token: string) => {
                  const promises = data.map((d: any) => {
                    return axios
                      .get(
                        GET_TOWER_DEVIATION_ANGLE_URL(
                          toLonLat(d.pointData.prevCoord).toString(),
                          toLonLat(d.pointData.currentCoord).toString(),
                          toLonLat(d.pointData.nextCoord).toString()
                        ),
                        {
                          headers: {
                            'Content-Type': 'application/json',
                            accept: 'application/json',
                            Authorization: token
                          }
                        }
                      )
                      .then((resp) => resp.data)
                      .then((res) => res.data);
                  });

                  const apiFinalRes = await Promise.all(promises).then((res) => {
                    return res;
                  });

                  if (!isEmpty(apiFinalRes)) {
                    if (currenttower.type === 'Gantry') {
                      feature.set('name', 'GNT');
                      feature.set('towertype', 'Gantry');
                      feature.set('deviationAngle', 0);
                      const prevtower = updateTowerData.find((tower) => {
                        return (
                          Number(tower.geometry.coordinates[0].toFixed(8)) ===
                            Number(toLonLat(prevPoint)[0].toFixed(8)) &&
                          Number(tower.geometry.coordinates[1].toFixed(8)) ===
                            Number(toLonLat(prevPoint)[1].toFixed(8))
                        );
                      });
                      const iprevtower = {
                        deviation_angle: apiFinalRes[0].deviation_angle,
                        geometry: { coordinates: toLonLat(prevPoint), type: 'Point' },
                        route_id: prevtower?.route_id,
                        id: prevtower?.id,
                        index: prevpointindex,
                        type: apiFinalRes[0].type,
                        name: prevtower?.name
                      };

                      setPrevPointData(iprevtower || null);

                      const updatedCurrentTowerpayloadData = {
                        index: pointIndex,
                        type_id: null,
                        coordinates: currentpoint,
                        deviation_angle: 0
                      };

                      const updatedprevTowerpayloadData = {
                        index: pointIndex,
                        type_id: find(towerTypes, ['name', apiFinalRes[0].type])?.id || null,
                        coordinates: toLonLat(prevPoint),
                        deviation_angle: apiFinalRes[0].deviation_angle
                      };

                      const updatedCurrentTowerData = {
                        id: currenttower.id,
                        type: 'Gantry',
                        deviation_angle: 0,
                        name: 'GNT',
                        route_id: currenttower.route_id,
                        geometry: { coordinates: currentpoint, type: 'Point' }
                      };

                      const updatedprevTowerData = {
                        id: prevtowerD.id,
                        type: apiFinalRes[0].type || null,
                        deviation_angle: apiFinalRes[0].deviation_angle,
                        name: prevtowerD.name,
                        route_id: prevtowerD.route_id,
                        geometry: { coordinates: toLonLat(prevPoint), type: 'Point' }
                      };

                      set(lineCoords, pointIndex, fromLonLat(currentpoint));

                      updateTowerData.splice(pointIndex, 0, updatedCurrentTowerData);
                      updateTowerData.splice(prevpointindex, 0, updatedprevTowerData);

                      oldData.splice(pointIndex, 0, updatedCurrentTowerpayloadData);
                      oldData.splice(prevpointindex, 0, updatedprevTowerpayloadData);

                      const lonlatlinecoordinates = [...lineCoords].map((coord: number[]) =>
                        toLonLat(coord)
                      );

                      const toweruniqdata = [...lonlatlinecoordinates]
                        .map((coord) => {
                          // Find a matching towerdata object
                          const match = [...updateTowerData].find(
                            (tower) =>
                              tower.geometry.coordinates[0].toFixed(8) === coord[0].toFixed(8) &&
                              tower.geometry.coordinates[1].toFixed(8) === coord[1].toFixed(8)
                          );
                          return match || null; // Return the match or null
                        })
                        .filter(
                          (item, index, self) => item !== null && self.indexOf(item) === index
                        );

                      const towerpayloaduniqdata = [...lonlatlinecoordinates]
                        .map((coord) => {
                          // Find a matching towerdata object
                          const match = [...oldData].find(
                            (tower) =>
                              tower.coordinates[0].toFixed(8) === coord[0].toFixed(8) &&
                              tower.coordinates[1].toFixed(8) === coord[1].toFixed(8)
                          );
                          return match || null; // Return the match or null
                        })
                        .filter(
                          (item, index, self) => item !== null && self.indexOf(item) === index
                        );

                      dispatch(setLineRouteCoords([...lineCoords]));
                      dispatch(setLineRouteLonLatCoords(lonlatlinecoordinates));
                      dispatch(setLineTowerDetails(uniq([...toweruniqdata])));
                      dispatch(setLineRouteTowerPayload(uniq([...towerpayloaduniqdata])));
                      setChangeTowerCoord(
                        [currentpoint[1].toFixed(4), currentpoint[0].toFixed(4)].toString()
                      );
                      setChangeTowerData({
                        towerName: 'GNT',
                        type: 'Gantry',
                        deviation_angle: 0
                      });
                    } else {
                      selectInteractionRef.current?.getFeatures().clear();
                      feature.set('towertype', apiFinalRes[1].type);
                      feature.set('deviationAngle', apiFinalRes[1].deviation_angle);
                      feature.set('name', `AP-${pointIndex}`);
                      if (!lineCoords[pointIndex - 2]) {
                        feature.set('towertype', apiFinalRes[0].type);
                        feature.set('deviationAngle', apiFinalRes[0].deviation_angle);
                        feature.set('name', `AP-${pointIndex}`);
                      }
                      if (!lineCoords[pointIndex + 2]) {
                        feature.set('towertype', apiFinalRes[1].type);
                        feature.set('deviationAngle', apiFinalRes[1].deviation_angle);
                        feature.set('name', `AP-${pointIndex}`);
                      }

                      const prevtower = updateTowerData.find((tower) => {
                        return (
                          Number(tower.geometry.coordinates[0].toFixed(8)) ===
                            Number(toLonLat(prevPoint)[0].toFixed(8)) &&
                          Number(tower.geometry.coordinates[1].toFixed(8)) ===
                            Number(toLonLat(prevPoint)[1].toFixed(8))
                        );
                      });

                      const nexttower = updateTowerData.find((tower) => {
                        return (
                          Number(tower.geometry.coordinates[0].toFixed(8)) ===
                            Number(toLonLat(nextPoint)[0].toFixed(8)) &&
                          Number(tower.geometry.coordinates[1].toFixed(8)) ===
                            Number(toLonLat(nextPoint)[1].toFixed(8))
                        );
                      });

                      let iprevtower: any = null;
                      // prev
                      if (lineCoords[pointIndex - 2]) {
                        iprevtower = {
                          deviation_angle: apiFinalRes[0].deviation_angle,
                          geometry: { coordinates: toLonLat(prevPoint), type: 'Point' },
                          route_id: prevtower?.route_id,
                          id: prevtower?.id,
                          index: prevpointindex,
                          type: apiFinalRes[0].type,
                          name: prevtower?.name
                        };
                      } else {
                        iprevtower = {
                          deviation_angle: 0,
                          geometry: { coordinates: toLonLat(prevPoint), type: 'Point' },
                          route_id: prevtower?.route_id,
                          id: prevtower?.id,
                          index: prevpointindex,
                          type: 'Gantry',
                          name: 'GNT'
                        };
                      }

                      // next
                      let inexttower: any = null;
                      if (lineCoords[pointIndex + 2]) {
                        inexttower = {
                          geometry: { coordinates: toLonLat(nextPoint), type: 'Point' },
                          route_id: nexttower?.route_id,
                          id: nexttower?.id,
                          index: nextpointindex,
                          type: lineCoords[pointIndex - 2]
                            ? apiFinalRes[2].type
                            : apiFinalRes[1].type,
                          deviation_angle: lineCoords[pointIndex - 2]
                            ? apiFinalRes[2].deviation_angle
                            : apiFinalRes[1].deviation_angle,
                          name: nexttower?.name
                        };
                      } else {
                        inexttower = {
                          geometry: { coordinates: toLonLat(nextPoint), type: 'Point' },
                          route_id: nexttower?.route_id,
                          id: nexttower?.id,
                          index: nextpointindex,
                          type: 'Gantry',
                          deviation_angle: 0,
                          name: 'GNT'
                        };
                      }

                      setPrevPointData(iprevtower || null);
                      setNextPointData(inexttower || null);

                      const updatedCurrentTowerpayloadData = {
                        index: pointIndex,
                        type_id: find(towerTypes, ['name', apiFinalRes[1].type])?.id || null,
                        coordinates: currentpoint,
                        deviation_angle: apiFinalRes[1].deviation_angle
                      };

                      // prev
                      let updatedprevTowerpayloadData: any = null;
                      if (lineCoords[pointIndex - 2]) {
                        updatedprevTowerpayloadData = {
                          index: pointIndex,
                          type_id: find(towerTypes, ['name', apiFinalRes[0].type])?.id || null,
                          coordinates: toLonLat(prevPoint),
                          deviation_angle: apiFinalRes[0].deviation_angle
                        };
                      } else {
                        updatedprevTowerpayloadData = {
                          index: pointIndex,
                          type_id: null,
                          coordinates: toLonLat(prevPoint),
                          deviation_angle: 0
                        };
                      }

                      // next
                      let updatednextTowerpayloadData: any = null;
                      if (lineCoords[pointIndex + 2]) {
                        updatednextTowerpayloadData = {
                          index: pointIndex,
                          type_id: lineCoords[pointIndex - 2]
                            ? find(towerTypes, ['name', apiFinalRes[2].type])?.id || null
                            : find(towerTypes, ['name', apiFinalRes[1].type])?.id || null,
                          coordinates: toLonLat(nextPoint),
                          deviation_angle: lineCoords[pointIndex - 2]
                            ? apiFinalRes[2].deviation_angle
                            : apiFinalRes[1].deviation_angle
                        };
                      } else {
                        updatednextTowerpayloadData = {
                          index: pointIndex,
                          type_id: null,
                          coordinates: toLonLat(nextPoint),
                          deviation_angle: 0
                        };
                      }

                      const towername = currenttower.name || '';

                      const updatedCurrentTowerData = {
                        id: currenttower.id,
                        type: apiFinalRes[1].type || null,
                        deviation_angle: apiFinalRes[1].deviation_angle,
                        name: towername,
                        route_id: currenttower.route_id,
                        geometry: { coordinates: currentpoint, type: 'Point' }
                      };

                      // prev
                      let updatedprevTowerData: any = null;
                      if (lineCoords[pointIndex - 2]) {
                        updatedprevTowerData = {
                          id: prevtowerD.id,
                          type: apiFinalRes[0].type || null,
                          deviation_angle: apiFinalRes[0].deviation_angle,
                          name: prevtowerD.name,
                          route_id: prevtowerD.route_id,
                          geometry: { coordinates: toLonLat(prevPoint), type: 'Point' }
                        };
                      } else {
                        updatedprevTowerData = {
                          id: prevtowerD.id,
                          type: 'Gantry',
                          deviation_angle: 0,
                          name: 'GNT',
                          route_id: prevtowerD.route_id,
                          geometry: { coordinates: toLonLat(prevPoint), type: 'Point' }
                        };
                      }

                      // next
                      let updatednextTowerData: any = null;
                      if (lineCoords[pointIndex + 2]) {
                        updatednextTowerData = {
                          id: nextower.id,
                          type: lineCoords[pointIndex - 2]
                            ? apiFinalRes[2].type || null
                            : apiFinalRes[1].type || null,
                          deviation_angle: lineCoords[pointIndex - 2]
                            ? apiFinalRes[2].deviation_angle
                            : apiFinalRes[1].deviation_angle,
                          name: nextower.name,
                          route_id: nextower.route_id,
                          geometry: { coordinates: toLonLat(nextPoint), type: 'Point' }
                        };
                      } else {
                        updatednextTowerData = {
                          id: nextower.id,
                          type: 'Gantry',
                          deviation_angle: 0,
                          name: 'GNT',
                          route_id: nextower.route_id,
                          geometry: { coordinates: toLonLat(nextPoint), type: 'Point' }
                        };
                      }

                      set(lineCoords, pointIndex, fromLonLat(currentpoint));

                      updateTowerData.splice(pointIndex, 0, updatedCurrentTowerData);
                      updateTowerData.splice(prevpointindex, 0, updatedprevTowerData);
                      updateTowerData.splice(nextpointindex, 0, updatednextTowerData);

                      oldData.splice(pointIndex, 0, updatedCurrentTowerpayloadData);
                      oldData.splice(prevpointindex, 0, updatedprevTowerpayloadData);
                      oldData.splice(nextpointindex, 0, updatednextTowerpayloadData);

                      const lonlatlinecoordinates = [...lineCoords].map((coord: number[]) =>
                        toLonLat(coord)
                      );

                      const toweruniqdata = [...lonlatlinecoordinates]
                        .map((coord) => {
                          // Find a matching towerdata object
                          const match = [...updateTowerData].find(
                            (tower) =>
                              tower.geometry.coordinates[0].toFixed(8) === coord[0].toFixed(8) &&
                              tower.geometry.coordinates[1].toFixed(8) === coord[1].toFixed(8)
                          );
                          return match || null; // Return the match or null
                        })
                        .filter(
                          (item, index, self) => item !== null && self.indexOf(item) === index
                        );

                      const towerpayloaduniqdata = [...lonlatlinecoordinates]
                        .map((coord) => {
                          // Find a matching towerdata object
                          const match = [...oldData].find(
                            (tower) =>
                              tower.coordinates[0].toFixed(8) === coord[0].toFixed(8) &&
                              tower.coordinates[1].toFixed(8) === coord[1].toFixed(8)
                          );
                          return match || null; // Return the match or null
                        })
                        .filter(
                          (item, index, self) => item !== null && self.indexOf(item) === index
                        );

                      dispatch(setLineRouteCoords([...lineCoords]));
                      dispatch(setLineRouteLonLatCoords(lonlatlinecoordinates));
                      dispatch(setLineTowerDetails(uniq([...toweruniqdata])));
                      dispatch(setLineRouteTowerPayload(uniq([...towerpayloaduniqdata])));
                      setChangeTowerCoord(
                        [currentpoint[1].toFixed(4), currentpoint[0].toFixed(4)].toString()
                      );
                      setChangeTowerData({
                        towerName: towername!,
                        type: apiFinalRes[1].type,
                        deviation_angle: Number(apiFinalRes[1].deviation_angle)
                      });
                    }
                  }
                  handleEditRoute();
                });
              }
            }
          }
        }
      });

      const select = new Select({
        condition: click,
        // filter: (feature) => feature.get('type') === 'Point'
        layers: [vectorLayerRef.current]
      });

      map.addInteraction(select);
      selectInteractionRef.current = select;

      select.on('select', (event) => {
        const selectedFeature = event.selected[0];

        setDeleteTower(true);
        if (selectedFeature && selectedFeature.getGeometry() instanceof Point) {
          setGntTowerData(null);
          // @ts-ignore
          const coordinates = selectedFeature.getGeometry().getCoordinates();
          const towerType = selectedFeature.get('towertype');
          const towerName = selectedFeature.get('name');
          const deviationAngle = selectedFeature.get('deviationAngle');
          const coord = toLonLat(coordinates);

          setDeleteTowerFeature(selectedFeature);
          setModifyTower(true);
          setChangeTowerCoord([coord[1].toFixed(4), coord[0].toFixed(4)].toString());
          setChangeTowerData({
            towerName,
            type: towerType,
            deviation_angle: Number(deviationAngle)
          });
          if (towerType === 'Gantry') {
            setGntTowerData({
              coordinates: coord,
              type: towerType,
              name: towerName,
              deviation_angle: Number(deviationAngle)
            });
            setDrawModeSaveEndRouteTitle(true);
            setModifyTower(false);
            setChangeTowerData(null);
          }
        }
      });

      setLoading(false);
      map.render();
      return () => {
        map.removeInteraction(select);
        map.removeInteraction(modify);
        modifyInteractionRef.current = undefined;
        vectorSourceRef.current?.clear();
      };
    }
  }, [map, lineRouteCoords, lineTowerDetails, isShowTowerText, vectorSourceRef.current, count]);

  // show length between two tower point
  useEffect(() => {
    if (!isEmpty(lineRouteLonLatCoords)) {
      const lineCoords = [...lineRouteLonLatCoords];
      // eslint-disable-next-line
      lineCoords.forEach((coord, i) => {
        const start = lineCoords[i];
        const end = lineCoords[i + 1];
        if (!(!start || !end || start.length < 2 || end.length < 2 || start[0] === end[0])) {
          const segmentDistance = getDistance(start, end).toFixed(2);
          const midpoint = fromLonLat([(start[0] + end[0]) / 2, (start[1] + end[1]) / 2]);
          const distanceFeature = new Feature({
            geometry: new Point(midpoint),
            zIndex: 0
          });
          distanceFeature.setStyle(
            new Style({
              text: new OlText({
                text: `${segmentDistance} m`,
                fill: new Fill({ color: editedRoute?.color }),
                placement: 'line',
                rotateWithView: true,
                rotation: 0,
                overflow: true,
                offsetY: -20
              })
            })
          );

          vectorSourceRef.current?.addFeature(distanceFeature);
        }
      });
    }
  }, [lineRouteLonLatCoords]);

  // gnt point draw mode
  useEffect(() => {
    let draw: any;

    if (map && drawMode && gntTowerData && !isEmpty(lineRouteCoords)) {
      const fullLineCoords = [...lineRouteCoords];
      const fullLineLonLatCoords = [...lineRouteLonLatCoords];

      const lineCoords = [fromLonLat(gntTowerData.coordinates)];
      const lineFeature = new Feature({
        geometry: new LineString(lineCoords),
        style: () => {
          return new Style({
            zIndex: 100,
            stroke: new Stroke({
              color: editedRoute?.color,
              width: 1,
              lineDash: [5, 10]
            })
          });
        }
      });
      vectorSourceRef.current?.addFeature(lineFeature);
      draw = new Draw({
        source: vectorSourceRef.current,
        type: 'LineString',
        style: new Style({
          zIndex: 100,
          stroke: new Stroke({
            color: '#ffffff',
            width: 1,
            lineDash: [5, 10]
          })
        }),
        geometryFunction: (coordinates, geometry) => {
          if (!geometry) {
            geometry = new LineString([...lineCoords]);
          }
          geometry.setCoordinates([...lineCoords, ...coordinates.slice(1)]);
          return geometry;
        }
      });
      map.addInteraction(draw);

      draw.on('drawend', (event: any) => {
        setDrawModeSaveEndRouteTitle(false);
        const newCoordinates = event.feature.getGeometry().getCoordinates();
        fullLineCoords.push(newCoordinates[1]);
        fullLineLonLatCoords.push(toLonLat(newCoordinates[1]));
        const gntPrevPointCoords = toLonLat(fullLineCoords[fullLineCoords.length - 2]);

        getAsyncStorageValue(ACCESS_TOKEN).then((token: string) => {
          dispatch(
            getDeviationAngleData(
              gntPrevPointCoords.toString(),
              toLonLat(newCoordinates[0]).toString(),
              toLonLat(newCoordinates[1]).toString(),
              token,
              (data: { type: string; deviation_angle: number }) => {
                selectInteractionRef.current?.getFeatures().clear();
                const oldTowerPayloadData = cloneDeep([...lineRouteTowerPayload]);

                oldTowerPayloadData[oldTowerPayloadData.length - 1].type_id =
                  find(towerTypes, ['name', data.type])?.id || null;

                const newlytowerpayloaddata = {
                  coordinates: toLonLat(newCoordinates[1]),
                  type_id: null,
                  index: oldTowerPayloadData[oldTowerPayloadData.length - 1].index + 1,
                  deviation_angle: Number(data.deviation_angle)
                };
                oldTowerPayloadData.push(newlytowerpayloaddata);

                const updateTowerDatas = cloneDeep([...lineTowerDetails]);

                updateTowerDatas[updateTowerDatas.length - 1].name = `AP-${
                  updateTowerDatas.length + 1
                }`;
                updateTowerDatas[updateTowerDatas.length - 1].type = data.type;
                updateTowerDatas[updateTowerDatas.length - 1].deviation_angle = String(
                  data.deviation_angle
                );
                const newlyGNTTowerData = {
                  id: maxTowerId! + 1,
                  route_id: updateTowerDatas[0].route_id,
                  type: 'Gantry',
                  deviation_angle: '0.0',
                  name: 'GNT',
                  geometry: {
                    type: 'Point',
                    coordinates: toLonLat(newCoordinates[1])
                  }
                };
                updateTowerDatas.push(newlyGNTTowerData);

                // @ts-ignore
                lineFeature.getGeometry().setCoordinates(fullLineCoords);

                dispatch(setLineRouteCoords(fullLineCoords));
                dispatch(setLineRouteLonLatCoords(fullLineLonLatCoords));
                dispatch(setLineTowerDetails([...updateTowerDatas]));
                dispatch(setLineRouteTowerPayload([...oldTowerPayloadData]));
                handleEditRoute();
                setDrawMode(false);

                setGntTowerData(null);
              }
            )
          );
        });
      });
    }

    return () => {
      if (draw) {
        map?.removeInteraction(draw);
      }
      modifyInteractionRef.current = undefined;
    };
  }, [drawMode, editedRoute, lineRouteCoords]);

  // show prev next tower point angle and type
  // eslint-disable-next-line
  useEffect(() => {
    if (prevPointData && nextPointData && map && vectorSourceRef.current) {
      const prevPointFeature = new Feature({
        geometry: new Point(fromLonLat(prevPointData.geometry.coordinates)),
        zIndex: 99
      });

      const nextPointFeature = new Feature({
        geometry: new Point(fromLonLat(nextPointData.geometry.coordinates)),
        zIndex: 99
      });

      prevPointFeature.setId(`tower-point-${prevPointData.id}-prev`);
      prevPointFeature.set('towertype', prevPointData?.type);
      prevPointFeature.set('deviationAngle', prevPointData?.deviation_angle);
      prevPointFeature.set('name', prevPointData?.name);
      prevPointFeature.setStyle(
        new Style({
          text: new OlText({
            text:
              prevPointData?.name && prevPointData?.deviation_angle
                ? `${prevPointData?.name} (${Number(prevPointData?.deviation_angle).toFixed(0)}°, ${
                    prevPointData?.type
                  })`
                : '',
            font: `12px`,
            fill: new Fill({ color: lightred }),
            stroke: new Stroke({
              color: white,
              width: 15
            }),
            offsetY: -20,
            offsetX: prevPointData.index % 2 === 0 ? 50 : -10
          }),
          image: undefined,
          zIndex: 101
        })
      );
      vectorSourceRef.current?.addFeature(prevPointFeature);

      nextPointFeature.setId(`tower-point-${nextPointData.id}-next`);
      nextPointFeature.set('towertype', nextPointData?.type);
      nextPointFeature.set('deviationAngle', nextPointData?.deviation_angle);
      nextPointFeature.set('name', nextPointData?.name);
      nextPointFeature.setStyle(
        new Style({
          text: new OlText({
            text:
              nextPointData?.name && nextPointData?.deviation_angle
                ? `${nextPointData?.name} (${Number(nextPointData?.deviation_angle).toFixed(0)}°, ${
                    nextPointData?.type
                  })`
                : '',
            font: `12px`,
            fill: new Fill({ color: lightred }),
            stroke: new Stroke({
              color: white,
              width: 15
            }),
            offsetY: -20,
            offsetX: nextPointData.index % 2 === 0 ? 50 : -10
          }),
          image: undefined,
          zIndex: 101
        })
      );
      vectorSourceRef.current?.addFeature(nextPointFeature);

      map.render();
      return () => {
        vectorSourceRef.current?.removeFeature(prevPointFeature);
        vectorSourceRef.current?.removeFeature(nextPointFeature);
      };
    }
  }, [map, prevPointData, nextPointData, vectorSourceRef.current]);

  const handleDrawmode = () => {
    setDrawMode(true);
    handleDrawModeGntPoint(true);
    setDrawModeSaveEndRoute(true);
  };

  // const handleDrawmodeEnd = () => {
  //   setDrawMode(false);
  //   modifyInteractionRef.current = undefined;
  // };

  const handleShowResumeRoute = () => {
    setResumeTowerModal(true);
  };

  const handleOkayResumeRoute = () => {
    handleConfirmResumeRoute();
  };

  const handleCancelResumeRoute = () => {
    setResumeTowerModal(false);
  };

  const handleUndoTowerPoint = () => {
    if (undoTowerData) {
      const linetowerdetail = cloneDeep([...lineTowerDetails]);
      const lineroutecoords = cloneDeep([...lineRouteCoords]);
      const lineroutetowerpayload = cloneDeep([...lineRouteTowerPayload]);
      const lineroutelonlatcoords = cloneDeep([...lineRouteLonLatCoords]);

      if (undoTowerData.type?.length > 0) {
        const lastdata = undoTowerData.type[undoTowerData.type.length - 1];
        lineroutetowerpayload[undoTowerData.index].type_id =
          find(towerTypes, ['name', lastdata])?.id || null;
        linetowerdetail[undoTowerData.index].type = lastdata;
      }
      if (undoTowerData.deviation_angle?.length > 0) {
        const lastdata = undoTowerData.deviation_angle[undoTowerData.deviation_angle.length - 1];
        lineroutetowerpayload[undoTowerData.index].deviation_angle = Number(lastdata);
        linetowerdetail[undoTowerData.index].deviation_angle = Number(lastdata);
      }
      if (undoTowerData.pointcoords?.length > 0) {
        const lastdata = undoTowerData.pointcoords[undoTowerData.pointcoords.length - 1];
        lineroutetowerpayload[undoTowerData.index].coordinates = lastdata;
        linetowerdetail[undoTowerData.index].geometry.coordinates = lastdata;
        lineroutecoords.splice(undoTowerData.index, 0, fromLonLat(lastdata));
        lineroutelonlatcoords.splice(undoTowerData.index, 0, lastdata);
      }

      dispatch(setLineTowerDetails([...linetowerdetail]));
      dispatch(setLineRouteCoords([...lineroutecoords]));
      dispatch(setLineRouteLonLatCoords([...lineroutelonlatcoords]));
      dispatch(setLineRouteTowerPayload([...lineroutetowerpayload]));
      if (
        undoTowerData.type?.length > 0 &&
        undoTowerData.deviation_angle?.length > 0 &&
        undoTowerData.pointcoords?.length > 0
      ) {
        const updatedundodata = {
          ...undoTowerData,
          type: [...undoTowerData.type].pop(),
          deviation_angle: [...undoTowerData.deviation_angle].pop(),
          pointcoords: [...undoTowerData.pointcoords].pop()
        };

        setUndoTowerData({
          ...updatedundodata
        });
      }
    }
  };

  return (
    <>
      <Spin
        className="spinner"
        spinning={loading}
        style={{
          width: '100%',
          position: 'absolute'
        }}
        indicator={<AiOutlineLoading className="spin-loader" speed={500} color={primary} />}
      />
      {isModifyTower && changeTowerData && changeTowerData.type !== 'Gantry' && (
        <AddNewPointPopUp
          isDeleteTower={false}
          isUndoTower={isUndoTower}
          towerCoord={changeTowerCoord}
          towerData={changeTowerData}
          handleClosePopUp={handleClosePopUp}
          handleConfirmEditRoute={handleConfirmEditRoute}
          handleDeletePoint={() => {}}
          // handleUndoTowerPoint={handleUndoTowerPoint}
          handleUndoTowerPoint={() => {}}
        />
      )}
      {gntTowerData && gntTowerData.type === 'Gantry' && (
        <AddnewGntPoint
          towers={editedRoute ? [...orderBy(editedRoute?.towers, 'id')] : []}
          newTowers={lineTowerDetails}
          gntTowerData={gntTowerData}
          handleClosePopUp={handleClosePopUp}
          handleDrawmode={handleDrawmode}
          handleShowResumeRoute={handleShowResumeRoute}
          handleDeletePoint={handleDeletePoint}
        />
      )}
      {isDeleteTower && changeTowerData && changeTowerData.type !== 'Gantry' && (
        <AddNewPointPopUp
          isDeleteTower={isDeleteTower}
          isUndoTower={isUndoTower}
          towerCoord={changeTowerCoord}
          towerData={changeTowerData}
          handleClosePopUp={handleClosePopUp}
          handleConfirmEditRoute={handleConfirmEditRoute}
          handleDeletePoint={handleDeletePoint}
          // handleUndoTowerPoint={handleUndoTowerPoint}
          handleUndoTowerPoint={() => {}}
        />
      )}
      {isResumeTowerModal && (
        <ResumeRoutGenerationModal
          isOpen={isResumeTowerModal}
          isLoading={isRequestingResumeRoute}
          handleOkayResumeRoute={handleOkayResumeRoute}
          handleCancelResumeRoute={handleCancelResumeRoute}
        />
      )}
      <DeleteRouteTowerModal
        isOpen={isDeleteTowerModal}
        isLoading={false}
        handleOkayDeleteRoute={handleOkayDeleteRoute}
        handleCancelDeleteRoute={handleCancelDeleteRoute}
      />
      {isShowDrawModeSaveEndRoute && (
        <div className="edit-mode draw-mode-route">
          <div>
            <div>Tower Addtion</div>
            {/* <Button
              type="link"
              onClick={handleUndoAddnewGntData}
              icon={<UndoIconV1 color="#696B72" />}
            /> */}
          </div>
          <div>
            <Button
              disabled={isShowDrawModeSaveEndRouteTitle}
              type="primary"
              onClick={() => {
                handleSaveDrawRoute();
              }}>
              Save
            </Button>
            <Button type="link" onClick={handleClosePopUp} icon={<CloseIcon color="#696B72" />} />
          </div>
        </div>
      )}
    </>
  );
};

export default EditRouteLayerV2;
