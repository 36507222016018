import React from 'react';
import { find } from 'lodash';
import { Button, Select as AntdSelect, Typography } from 'antd';
import LocationPinIcon from 'assets/svg/LocationPinIcon';
import TowerIcon from 'assets/svg/TowerIcon';
import AngleIcon from 'assets/svg/AngleIcon';
import CloseIcon from 'assets/svg/CloseIcon';
import DeleteIcon from 'assets/svg/DeleteIcon';
import { useAppSelector } from 'hooks/useReduxHook';
import { getTowerTypeOptions } from 'store/route';
import UndoIcon from 'assets/svg/UndoIcon';

const { Text } = Typography;

interface AddNewPointPopUpPropstypes {
  towerData: {
    type: string;
    towerName: string;
    deviation_angle: number;
  } | null;
  towerCoord: string | null;
  isDeleteTower: boolean;
  isUndoTower: boolean;
  handleClosePopUp: () => void;
  handleConfirmEditRoute: () => void;
  handleDeletePoint: () => void;
  handleUndoTowerPoint: () => void;
}

const AddNewPointPopUp = ({
  towerData,
  towerCoord,
  isDeleteTower,
  isUndoTower,
  handleClosePopUp,
  handleConfirmEditRoute,
  handleUndoTowerPoint,
  handleDeletePoint
}: AddNewPointPopUpPropstypes) => {
  const towerOptions = useAppSelector((state) => getTowerTypeOptions(state));

  return (
    <div className="line-new-point-popup">
      <div className="modal-title-new-point">
        <div>
          <LocationPinIcon />
          <Text className="tower-name">{towerData ? towerData.towerName : ''}</Text>
          <Text className="tower-coord">{towerCoord ? `(${towerCoord})` : ''}</Text>
        </div>
        <CloseIcon onClick={handleClosePopUp} />
      </div>
      <div>
        <div>
          <TowerIcon />
          <AntdSelect
            showArrow
            className="tower-type"
            onChange={() => {}}
            aria-readonly
            value={towerData ? find(towerOptions, ['label', towerData.type]) : undefined}
            options={towerOptions}
            popupClassName="tower-type-select-popup"
            placeholder="Select Type"
          />
        </div>
        <div>
          <AngleIcon />
          <Text>{towerData ? `${towerData.deviation_angle.toFixed(0)}°` : ''} </Text>
        </div>
      </div>
      <div
        className={
          isDeleteTower || isUndoTower ? 'line-new-btns delete-icon-div' : 'line-new-btns'
        }>
        <div>
          {isDeleteTower && (
            <Button
              className="delete-btn"
              onClick={handleDeletePoint}
              type="text"
              icon={<DeleteIcon color="#696B72" height={18} width={18} />}
            />
          )}
          {isUndoTower && (
            <Button
              className="delete-btn"
              onClick={handleUndoTowerPoint}
              type="text"
              icon={<UndoIcon color="#696B72" />}
            />
          )}
        </div>
        {!isDeleteTower && (
          <Button type="link" className="confirm-btn" onClick={handleConfirmEditRoute}>
            confirm
          </Button>
        )}
      </div>
    </div>
  );
};

export default AddNewPointPopUp;
