import { white } from 'constants/theme.constants';
import { IconProps } from 'types/common.types';

const UndoIcon = ({ color, height, width, onClick }: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 18 18"
      fill="none"
      onClick={onClick}
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.8405 8.62535C10.5127 8.62535 12.9233 9.85085 14.448 11.8196C13.7467 8.91785 11.0505 6.75035 7.8405 6.75035C7.42575 6.75035 7.0905 6.4151 7.0905 6.00035V4.5581L3.4725 7.6886L7.0905 10.8476V9.37535C7.0905 8.9606 7.42575 8.62535 7.8405 8.62535ZM15.1658 15.7504C14.82 15.7504 14.5185 15.5141 14.4368 15.1781C13.7693 12.4414 11.421 10.4644 8.5905 10.1651V11.0059C8.5905 11.5354 8.27625 12.0071 7.77075 12.2359C7.2225 12.4856 6.58275 12.3956 6.138 12.0079L2.3385 8.68985C2.04375 8.43185 1.875 8.0666 1.875 7.68785C1.875 7.3091 2.04375 6.94385 2.3385 6.68585L6.138 3.36785C6.58275 2.9801 7.2225 2.8901 7.77075 3.13985C8.27625 3.3686 8.5905 3.84035 8.5905 4.36985V5.28335C12.8085 5.6531 16.125 9.11285 16.125 13.3129C16.125 13.9204 16.047 14.5474 15.894 15.1774C15.813 15.5141 15.5123 15.7504 15.1658 15.7504Z"
        fill={color}
      />
    </svg>
  );
};
UndoIcon.defaultProps = {
  color: white,
  height: 18,
  width: 18
};

export default UndoIcon;
