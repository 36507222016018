import { greyShed6 } from 'constants/theme.constants';
import { IconProps } from 'types/common.types';

const PlayIcon = ({ color, height, width, classname, onClick }: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      onClick={onClick}
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={classname}>
      <path
        d="M4.66675 3.5L10.5001 7L4.66675 10.5V3.5Z"
        fill={color}
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
PlayIcon.defaultProps = {
  color: greyShed6,
  height: 14,
  width: 14,
  classname: '',
  onClick: () => {}
};

export default PlayIcon;
